<template>
  <section class="path-user">
    <div class="layout-container forgot-password-page">
      <main role="main" class="main-content-wrapper">
        <div class="login-wrapper">
          <AuthHeader />

          <transition name="scale">
            <div class="block-webform-block col-md-6 col-md-offset-3" v-if="!emailSent" key="reset_password">
              <h3 class="form-title">Forgot Password</h3>
              <div class="alert alert-danger" role="alert" v-if="emailNotFound" style="margin-bottom: 10px">Email not found</div>
              <form @submit.prevent="submit()" ref="loginForm" class="user-login-form weblab-form" id="user-login-form">
                <div class="form-item required">
                  <label class="js-form-required form-required" style="display: none">E-mail</label>
                  <input
                    required
                    placeholder="E-mail"
                    autocorrect="none"
                    autocapitalize="none"
                    label="E-mail"
                    name="login"
                    v-model="email"
                    type="email"
                    size="60"
                    maxlength="60"
                    class="form-text required"
                  />
                  <div class="description">An e-mail will be sent to this address with further instructions to reset your password.</div>
                </div>
                <div class="clearfix"></div>

                <div data-drupal-selector="edit-actions" class="form-actions js-form-wrapper form-wrapper" id="edit-actions" style="margin-top: 20px">
                  <input type="submit" id="edit-submit" name="op" value="Submit" class="button js-form-submit form-submit" />
                  <span class="glyphicon glyphicon-arrow-right"></span>
                </div>
              </form>
            </div>
            <div v-else class="alert alert-info alert-custom" key="success">
              <div class="checkmark-wrapper info">
                <!-- <i class="glyphicon glyphicon-ok"></i> -->
                <i class="glyphicon glyphicon-exclamation-sign"></i>
              </div>
              <h3 class="info">Email Sent</h3>
              <p>
                An e-mail has been sent to <strong>{{ email }}</strong> with further instructions to reset your password.
              </p>
            </div>
          </transition>
          <router-link to="login" class="btn-register">Go to Login</router-link>
        </div>
      </main>
    </div>
  </section>
</template>

<script>
import AuthHeader from '@/components/generic/AuthHeader.vue';
import * as helpers from '@/helpers/functions.js';

export default {
  components: {
    AuthHeader,
  },
  data() {
    return {
      email: '',
      emailNotFound: false,
      emailSent: false,
    };
  },
  created() {
    if (this.$store.state.auth.accessToken) this.$router.push({ name: 'CreateOrder' });
  },
  methods: {
    async submit() {
      this.emailNotFound = false;
      this.$store.commit('setTransLoader', true);
      this.$store
        .dispatch('FORGOT_PASSWORD', { email: this.email })
        .then((res) => {
          if (res.state == 'success') {
            this.emailSent = true;
          } else {
            helpers.displayErrorNoRetry(res?.response?.data?.message ?? 'Error submiting password', this);
          }
        })
        .catch((error) => {
          this.emailNotFound = false;
          helpers.displayErrorNoRetry(error?.response?.data?.message ?? 'Error submiting password', this);
        })
        .finally(() => this.$store.commit('setTransLoader', false));
    },
    showError() {
      this.emailSent = false;
      this.$snotify.error(
        `Error submtting mail. Please try again. If problems persists please call ${this.$store.state.config.supportTel} for further assistance.`,
        '',
        {
          timeout: 0,
          showProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          position: 'centerTop',
        }
      );
    },
  },
};
</script>

<style lang="scss">
.forgot-password-page {
  .btn-register {
    margin-top: 40px;
    margin-left: 10px;
    line-height: 1.5rem;
    text-align: center;
    box-shadow: none;
    font-size: 1.2rem;
  }
  .buttons-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media only screen and (max-width: 480px) {
  .forgot-password-page .block-webform-block {
    padding: 20px;
  }
}
</style>