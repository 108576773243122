<template>
  <div class="block block-system block-system-branding-block" style="display: flex; justify-content: center; margin-bottom: 10px">
    <a :href="$store.state.config.baseUrlContentSite" title="Home" rel="home" class="site-logo">
      <img :src="require('@/assets/img/logos/logo-web-nv.svg')" alt="Home" />
    </a>

    <div class="site-slogan">
      Online <br />
      laboratory <br />
      platform
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>